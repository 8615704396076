/**
 * The Root container
 */
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Loadable from "react-loadable";
import { useSsrEffect } from "@issr/core";
import { useWeb3React } from "@web3-react/core";
import FourOFour from "./FourOFour";
import { isServer } from "@bkry/bowline-redux";
import { ProjectOnboarding } from "@bkry/bowline-components/ProjectOnboarding";
import { showProject } from "@bkry/bowline-redux/projects";
import { useEffect } from "react";
import { PrivateRoute } from "@bkry/bowline-components/PrivateRoute";
import { HeaderLayout } from "@bkry/bowline-components/HeaderLayout";
import { FooterLayout } from "@bkry/bowline-components/FooterLayout";
import { env } from "@bkry/bowline-utils";
import ProjectDesignLoader from "../components/ProjectDesignLoader";
import { keepWalletConnected } from "@bkry/bowline-utils";
const { REACT_APP_PROJECT_ID, REACT_APP_DASHBOARD_REDIRECT } = env;
/**
 * Loadable import for Home container
 */
// const Landingpage = Loadable({
//   loader: () => import(/* webpackChunkName: "Landingpage" */ './Landingpage'),
//   loading: () => null,
//   modules: ['Landingpage']
// });

// /**
//  * Loadable import for Dashboard container
//  */
// const Dashboard = Loadable({
//   loader: () => import(/* webpackChunkName: "Dashboard" */ './Dashboard'),
//   loading: () => null,
//   modules: ['Dashboard']
// });

// const UserProfile = Loadable({
//   loader: () => import(/* webpackChunkName: "UserProfile" */ './UserProfile'),
//   loading: () => null,
//   modules: ['UserProfile']
// });

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./Dashboard"),
  loading: () => null,
  modules: ["Dashboard"],
});

const Whitelist = Loadable({
  loader: () => import(/* webpackChunkName: "Whitelist" */ "./Whitelist"),
  loading: () => null,
  modules: ["Whitelist"],
});

const EventsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "EventsRoot" */ "./Events/EventsRoot"),
  loading: () => null,
  modules: ["EventsRoot"],
});

const UnlockableContentsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UnlockableContentsRoot" */ "./UnlockableContents/UnlockableContentsRoot"
    ),
  loading: () => null,
  modules: ["UnlockableContentsRoot"],
});

const VotingsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "VotingsRoot" */ "./Votings/VotingsRoot"),
  loading: () => null,
  modules: ["Votings"],
});

const PartnerDealsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PartnerDeals" */ "./PartnerDeals/PartnerDealsRoot"
    ),
  loading: () => null,
  modules: ["PartnerDeals"],
});

const TokensRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TokensRoot" */ "./Tokens/TokensRoot"),
  loading: () => null,
  modules: ["TokensRoot"],
});

const MintingSectionRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MintingSection" */ "./Minting/MintingSectionRoot"
    ),
  loading: () => null,
  modules: ["MintingSection"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
function Root() {
  const { account, connector, ...rest } = useWeb3React();
  const keepWalletConnection = useSelector(
    (state) => state.wallets.keepWalletConnection
  );
  useEffect(() => {
    if (keepWalletConnection && !account) {
      keepWalletConnected(account, null, connector, rest);
    }
    // console.log(keepWalletConnected, account, null, connector, rest);
  }, [account]);
  if (!isServer) window.account = account;
  const dispatch = useDispatch();
  const dispatchShowProject = async () => {
    dispatch(showProject(REACT_APP_PROJECT_ID));
  };

  useSsrEffect(dispatchShowProject);
  useEffect(dispatchShowProject, [dispatch, REACT_APP_PROJECT_ID]);

  return (
    <>
      <ProjectDesignLoader />
      <StickyContainer id="Root">
        <HeaderLayout />
        {account && <ProjectOnboarding />}
        <div id="content">
          <div id="randomTest"></div>
          <Switch>
            {REACT_APP_DASHBOARD_REDIRECT && (
              <Route path="/" exact>
                <Redirect to={{ pathname: REACT_APP_DASHBOARD_REDIRECT }} />
              </Route>
            )}
            {!REACT_APP_DASHBOARD_REDIRECT && (
              <Route path="/" exact component={Dashboard} />
            )}
            <PrivateRoute path={"/allowlist"} component={Whitelist} />
            <PrivateRoute path="/contents" component={UnlockableContentsRoot} />
            <PrivateRoute path="/events" component={EventsRoot} />
            <PrivateRoute path={"/votings"} component={VotingsRoot} />
            <PrivateRoute path="/deals" component={PartnerDealsRoot} />
            <PrivateRoute path="/tokens" component={TokensRoot} />
            <Route path="/minting" component={MintingSectionRoot} />

            {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
            {/* new routes */}

            <Route component={FourOFour} />
          </Switch>
        </div>
        <FooterLayout />
      </StickyContainer>
    </>
  );
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = () => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
