/**
 * The 404 component
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Page } from "@bkry/bowline-components/Page";
import { ScrollToTop as useScrollToTop } from "@bkry/bowline-components/Utils";
import { ConnectWallet } from "@bkry/bowline-components/ConnectWallet";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { getWalletAttributes, showWallet } from "@bkry/bowline-redux/wallets";
import { SignInWithEthereumButton } from "@bkry/bowline-components/SignInWithEthereumButton";
import { env } from "@bkry/bowline-utils";
const { REACT_APP_PROJECT_ID } = env;

const FourOFour = (props, context) => {
  useScrollToTop();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const accessMode = location?.state?.accessMode || undefined;
  const accessErrorCode = location?.state?.accessErrorCode || undefined;
  const path = location?.state?.path || undefined;
  const errorTitle = {
    404: context.t("Page not found"),
    403: context.t("You are not authorized to see this page!"),
  };

  const errorText = {
    none: "",
    connected_wallets: context.t("Please connect your wallet first"),
    signed_in_wallets: context.t("Please sign in with ethereum first"),
    holder_wallets: context.t(
      "You need to own one of our NFTs to view this page."
    ),
  };

  const errorCode = accessErrorCode || 404;
  const accessErrorType = accessMode || "none";
  const { account } = useWeb3React();
  const wallet = useSelector((state) => getWalletAttributes(state, account));
  const refreshWallet = () => {
    if (account)
      dispatch(
        showWallet({ address: account, project_id: REACT_APP_PROJECT_ID })
      );
  };
  useEffect(() => {
    if (accessErrorCode === 403 && path) {
      if (
        (accessMode === "connected_wallets" && account) ||
        (accessMode === "signed_in_wallets" && wallet) ||
        (accessMode === "holder_wallets" && wallet?.current_wallet_is_holder)
      ) {
        history.replace(path);
      }
    }
    // window.scrollTo(0, 0);
  }, [accessErrorCode, accessMode, path, account, wallet]);

  const message = () => {
    if (!account) {
      return (
        <>
          {context.t("Connect your wallet to proceed.")}
          <br />
          <Link to="/minting">
            {context.t(
              "You didn't purchase our NFT yet? Click here to proceed."
            )}
          </Link>
        </>
      );
    } else if (!wallet) {
      return context.t(
        "Please click Sign in with Ethereum to verify your NFT Ownership."
      );
    }
  };

  return (
    <Page id="404" title={errorTitle[errorCode]}>
      <section className="mt-5">
        <Container>
          <Row>
            <Col xs={12} md={{ size: 6, offset: 3 }} className="text-center">
              <h1 className="mb-3 h3">
                {errorText[accessErrorType] || errorTitle[errorCode]}
              </h1>
              <p>
                {message()}
                {accessErrorType === "holder_wallets" &&
                  wallet &&
                  !wallet?.current_wallet_is_holder && (
                    <>
                      {context.t(
                        "If you have already minted please wait a couple minutes in order for the transaction to be completed and "
                      )}
                      <span
                        className="btn-link cursor-pointer"
                        onClick={refreshWallet}
                      >
                        {context.t("click here ")}
                      </span>
                      {context.t("to check your holder status.")}
                    </>
                  )}
              </p>
              {!account && (
                <ConnectWallet
                  buttonColor="primary"
                  buttonSize="xl"
                  buttonId="nav-connect-wallet"
                  className="mt-2"
                  block
                />
              )}
              {account && !wallet && (
                <SignInWithEthereumButton
                  color="primary"
                  size="xl"
                  block
                  onSuccess={() => {}}
                />
              )}

              {account && wallet && !wallet.current_wallet_is_holder && (
                <Link to={`/minting`} className="btn btn-primary w-100">
                  {context.t("Become a Holder")}
                </Link>
              )}
              <Link to={`/`} className="btn btn-secondary w-100 mt-3">
                {context.t("Back to Home")}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Page>
  );
};
/**  define proptype for the 'translation' function  */
FourOFour.contextTypes = {
  t: PropTypes.func,
};

export default FourOFour;
